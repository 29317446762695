export const getEditDistance = (
  lookupString,
  resultString,
  lookupStringLength,
  resultStringLength
) => {
  var computationMatrix = []
  for (let i = 0; i <= lookupStringLength; i++) {
    computationMatrix[i] = []
    for (let j = 0; j <= resultStringLength; j++) {
      computationMatrix[i][j] = 999
    }
  }

  for (let i = 0; i <= lookupStringLength; i++) {
    for (let j = 0; j <= resultStringLength; j++) {
      if (i === 0) computationMatrix[i][j] = j
      else if (j === 0) computationMatrix[i][j] = i
      else if (lookupString[i - 1] === resultString[j - 1])
        computationMatrix[i][j] = computationMatrix[i - 1][j - 1]
      else
        computationMatrix[i][j] =
          1 +
          Math.min(
            computationMatrix[i][j - 1], // Insert
            computationMatrix[i - 1][j], // Remove
            computationMatrix[i - 1][j - 1]
          ) // Replace
    }
  }

  return computationMatrix[lookupStringLength][resultStringLength]
}

export const fuzzySearch = (lookupString, dataSource, lookupKeys) => {
  for (let i = 0; i < dataSource.length; i++) {
    dataSource[i].distance = null
  }

  for (let i = 0; i < lookupKeys.length; i++) {
    for (let j = 0; j < dataSource.length; j++) {
      if (dataSource[j][lookupKeys[i]]) {
        let tempLookupString = lookupString.toLowerCase()
        let tempResultString = dataSource[j][lookupKeys[i]].toLowerCase()
        let maxLength = Math.max(
          tempLookupString.length,
          tempResultString.length
        )

        let tempDistance =
          tempResultString.split(" ").length === 1 &&
          tempResultString.includes(tempLookupString)
            ? 1
            : (maxLength -
                getEditDistance(
                  tempLookupString,
                  tempResultString,
                  tempLookupString.length,
                  tempResultString.length
                )) /
              maxLength

        if (dataSource[j].distance)
          if (tempDistance > dataSource[j].distance) {
            dataSource[j].distance = tempDistance
            dataSource[j].closestMatch = lookupKeys[i]
          } else continue
        else {
          dataSource[j].distance = tempDistance
          dataSource[j].closestMatch = lookupKeys[i]
        }
      } else {
        dataSource[j].distance = -999
        dataSource[j].closestMatch = lookupKeys[i]
      }
    }
  }

  let sortedData = dataSource.sort((a, b) =>
    b.distance - a.distance === 0
      ? a[a.closestMatch].toLowerCase().indexOf(lookupString.toLowerCase()) -
          b[b.closestMatch]
            .toLowerCase()
            .indexOf(lookupString.toLowerCase()) ===
        0
        ? a[a.closestMatch].length - b[b.closestMatch].length
        : a[a.closestMatch].toLowerCase().indexOf(lookupString.toLowerCase()) -
          b[b.closestMatch].toLowerCase().indexOf(lookupString.toLowerCase())
      : b.distance - a.distance
  )

  sortedData.sort((a, b) => {
    const aIncludesLookupString =
      a?.productTitle?.toLowerCase()?.includes(lookupString?.toLowerCase()) ||
      false
    const bIncludesLookupString =
      b?.productTitle?.toLowerCase()?.includes(lookupString?.toLowerCase()) ||
      false

    if (aIncludesLookupString && !bIncludesLookupString) {
      return -1
    } else if (!aIncludesLookupString && bIncludesLookupString) {
      return 1
    } else {
      return 0
    }
  })

  return sortedData
}
