import React, { Fragment, useEffect, useRef } from "react"
import classNames from "classnames"
import { Field, ErrorMessage, useField, useFormikContext } from "formik"

import handleScrollToError from "./utils/handleScrollToError"

/**
 ** Input field with label and error message.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'placeholder': String,
 **               'label': String,
 **               'type': String,
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormInput = (fieldProps) => {
  const formik = useFormikContext()
  const fieldRef = useRef(null)

  const handleScrollCallback = () => {
    fieldRef.current.focus()
  }

  useEffect(() => {
    handleScrollToError({
      formikContext: formik,
      fieldName: fieldProps.name,
      callback: handleScrollCallback,
    })
  }, [formik.submitCount, formik.isValid])

  //* Function that prevents alpha and symbols
  //* if the fieldProps.type is number.
  //* This also prevents the user to input characters more than
  //* fieldProps.max (if defined).

  // We're accessing the useField props below so we can validate forms inline, on touch
  // Source: https://jaredpalmer.com/formik/docs/api/useField#usefieldname-string-fieldattributes-fieldinputprops-fieldmetaprops-fieldhelperprops
  const isTypeNumber =
    fieldProps.type === "number" ||
    fieldProps.type === "employeeIDNumber" ||
    fieldProps.type === "phoneNumber"
  const handleOnKeyPress = (event) => {
    if (isTypeNumber) {
      if (
        (event.key !== "." && isNaN(event.key)) ||
        (fieldProps.maxLength &&
          event.target.value.toString().length >= fieldProps.maxLength)
      )
        event.preventDefault()
    }
    if (fieldProps.onKeyPress) fieldProps.onKeyPress(event)
  }

  // We're accessing the useField props below so we can validate forms inline, on touch
  // Source: https://jaredpalmer.com/formik/docs/api/useField#usefieldname-string-fieldattributes-fieldinputprops-fieldmetaprops-fieldhelperprops
  const [, { touched, error }] = useField(fieldProps.name)
  return (
    <div>
      <label className={classNames("label has-text-weight-normal")}>
        {!!fieldProps.labelIcon && (
          <span className={`icon has-text-${fieldProps.labelIconColor}`}>
            {fieldProps.labelIcon}
          </span>
        )}
        {fieldProps.label}
        {!fieldProps.isRequired && !fieldProps.hideOptional && (
          <span className="has-text-grey is-italic"> (Optional)</span>
        )}
        {!!fieldProps.helper && (
          <span
            className={classNames(
              "help has-text-weight-normal",
              fieldProps.helperClassName
            )}
          >
            {fieldProps.helper}
          </span>
        )}
      </label>
      <div
        className={`field mb-0 ${
          fieldProps.hasAddons && "has-addons has-addons-right"
        }`}
      >
        {fieldProps.hasAddons && fieldProps.addonLeft && (
          <div className="control">{fieldProps.addonLeft}</div>
        )}
        <div className={`control ${fieldProps.hasAddons && "is-expanded"}`}>
          <Field {...fieldProps}>
            {({ field }) => (
              <input
                {...fieldProps}
                {...field}
                ref={fieldRef}
                className={classNames(
                  fieldProps.type === "employeeIDNumber" ||
                    fieldProps.type === "phoneNumber"
                    ? "input employeeIDNumber"
                    : "input",
                  {
                    "is-success": touched && !error && fieldProps.isRequired,
                    "is-danger": touched && error,
                  },
                  fieldProps.className
                )}
                onKeyPress={handleOnKeyPress}
                onPaste={(event) => {
                  if (!!fieldProps?.disablePaste) event.preventDefault()
                }}
              />
            )}
          </Field>
        </div>
        {fieldProps.hasAddons && fieldProps.addonRight && (
          <div className="control">{fieldProps.addonRight}</div>
        )}
      </div>
      <p className="help mt-0 mb-1 is-danger">
        <ErrorMessage name={fieldProps.name} />
      </p>
    </div>
  )
}

export default React.memo(FormInput)
