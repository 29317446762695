import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

import FormInput from "../../Elements/Form/FormInput"
import Button from "../../Elements/Button"
import handleInputNumber from "../../Cart/utils/handleInputNumber"

const MedicineQuantityInput = ({ setFieldValue, qty, changeQuantity }) => (
  <FormInput
    hasAddons
    addonLeft={
      <Button
        onClick={() => changeQuantity(setFieldValue, parseInt(qty) - 1)}
        color="danger"
        isDisabled={parseInt(qty) === 1}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
    }
    addonRight={
      <Button
        onClick={() => changeQuantity(setFieldValue, parseInt(qty) + 1)}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    }
    name="qty"
    className="has-text-centered"
    type="number"
    pattern="[0-9]"
    min={1}
    max={200}
    maxLength={3}
    hideOptional
    onKeyPress={(event) => {
      if (isNaN(event.key)) event.preventDefault()
    }}
  />
)

export default MedicineQuantityInput
