import React from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import Button from "./Button"

const ActionButtons = ({ submit, next, back }) => {
  return(
  <div className="buttons is-centered">
    {back && (
      <Button
        onClick={() => {
          if (back.callback) {
            back.callback()
            return
          }
          navigate(back.link)
        }}
        className="px-2 px-2-mobile mr-1 mr-1-mobile"
        size="medium"
      >
        {back.label}
      </Button>
    )}

    {next && (
      <Button
        onClick={() => navigate(next.link)}
        className="px-2 px-2-mobile mr-1 mr-1-mobile"
        size="medium"
        color="primary"
        isDisabled={next.disabled}
      >
        {next.label}
      </Button>
    )}

    {submit && (
      <Button
        className="px-2 px-2-mobile mr-1 mr-1-mobile"
        size="medium"
        color="primary"
        type="submit"
        isLoading={submit.loading}
        isDisabled={submit.disabled}

      >
        {submit.label}
      </Button>
    )}
  </div>
)}

export default ActionButtons
