import FormInput from "../../Elements/Form/FormInput"
import React from "react"
import Button from "../../Elements/Button"

const MedicineDosageInput = ({ setFieldValue }) => {
  return (
    <div className="control">
      <FormInput
        name="indication"
        label="Indication"
        helper="What condition does this medicine treat?"
        type="text"
        hideOptional
      />
      <FormInput
        name="dailyIntake"
        label="Daily Intake"
        className="has-text-left"
        type="text"
        maxLength={5}
        helper="How many are you taking per day?"
        hideOptional
        hasAddons={true}
        addonRight={<Button className="is-static"> per day </Button>}
        onKeyPress={(event) => {
          if (!/^[0-9.]$/.test(event.key)) event.preventDefault()

          const wholeNumber = parseInt(event?.target?.value?.split(".")?.[0])

          if (isNaN(wholeNumber) && event.key === ".") event.preventDefault()

          if (
            wholeNumber === 0 &&
            !event.target.value?.includes(".") &&
            event.key !== "."
          )
            event.preventDefault()

          const decimals = event?.target?.value?.split(".")?.[1]?.toString()

          if (wholeNumber === 10) {
            event.preventDefault()
            setFieldValue("dailyIntake", "10")
          }

          if (decimals?.length >= 2) event.preventDefault()
        }}
        onChange={(event) => {
          if (event.target.value > 10) {
            setFieldValue("dailyIntake", "10")
          }
        }}
        disablePaste
      />
      <FormInput
        name="otherSpecifications"
        label="Others"
        helper="Let us know if you take the meds irregularly?"
        placeholder="e.g. pill-split, every other day, as needed"
        type="text"
        hideOptional
      />
    </div>
  )
}

export default MedicineDosageInput
