import React, { useContext, useEffect } from "react"
import { Link, navigate } from "gatsby"
import CartLayout from "../Layout/CartLayout"
import Cart from "./Cart"
import Search from "../Search/Search"
import AddMedicineModal from "./Order/AddMedicineModal"

import { AppContext } from "../../context/AppContext"
import { useEpharmacyProducts } from "./hooks/useEpharmacyProducts"
import { isMedicineAlreadyInCart } from "./services/cart"
import { useEmployees } from "./hooks/useEmployees"

const Order = ({ backRoute, nextRoute, location }) => {
  const data = useEpharmacyProducts()

  const { dispatch, state } = useContext(AppContext)
  const { epharmacy } = state
  const { medicines } = epharmacy
  const hasNoMeds = !medicines.length

  /**
   * for employees with CBA, show products only with cba tag,
   * for employees without CBA tag, show all products
   */
  let allEmployees = useEmployees() || []

  let employeeType = allEmployees.find(
    (employee) => employee.employeeId === epharmacy?.employeeIDNumber
  )?.employeeType
  let defaultProducts = data.filter((product) => product?.tags === "CBA")

  if (employeeType !== "CBA") {
    let additionalProducts = data.filter((product) => product?.tags !== "CBA")
    defaultProducts = [...defaultProducts, ...additionalProducts]
  }

  const handleOnSelect = (item) => {
    if (isMedicineAlreadyInCart(item, state.epharmacy.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${item.productTitle} already in added`,
          color: "danger",
        },
      })
    else
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Add medicine",
          isCard: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: (
            <AddMedicineModal addToCart={addMedicineToCart} medicine={item} />
          ),
        },
      })
  }
  //TODO: push new fields here dosage, duration
  const addMedicineToCart = async (
    item,
    qty,
    indication,
    dailyIntake,
    others
  ) => {
    const medicineList = [...state.epharmacy.medicines]
    if (!item.molecule)
      medicineList.push({
        ...item,
        qty: qty,
        indication: indication,
        dailyIntake: dailyIntake,
        others: others,
      })
    else
      medicineList.push({
        ...item,
        qty: qty,
        indication: indication,
        dailyIntake: dailyIntake,
        others: others,
      })
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: medicineList },
      },
    })
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Added ${item.molecule}`,
        color: "success",
      },
    })
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    if (location?.state?.selectedMedicine) {
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `Added ${location?.state?.selectedMedicine} to cart`,
          color: "success",
        },
      })
      dispatch({
        type: "SHOW_CART",
      })
    }
  }, [])

  // const checkIfMedicineQuantitiesAreEmpty = medicines => {
  //   let emptyQuantities = medicines.filter(
  //     medicine => medicine.qty < 1 || !medicine.qty
  //   )

  //   return emptyQuantities.length > 0
  // }
  const searchComponentProps = {
    placeholder: "Atorvastatin or Lipitor",
    label: "Medicine Search",
    dataSource: {
      type: "graphql",
      data: defaultProducts,
      lookupKeys: ["molecule", "brand"],
    },
    onSelect: handleOnSelect,
  }

  return (
    <CartLayout
      cartContainer={
        <Cart
          open={{ medicinesCard: true }}
          hidden={{ documents: true, medicines: false }}
        />
      }
      desktop={10}
      fullhd={8}
      isCentered
      next={{
        label: "Checkout",
        disabled: hasNoMeds,
        link: nextRoute,
      }}
      back={{
        label: "Back",
        link: backRoute,
      }}
    >
      <div className="mt-1">
        <div className="is-hidden-mobile">
          <Search {...searchComponentProps} />
        </div>
        <div className="is-hidden-desktop is-hidden-tablet">
          <Link to="/epharmacy/search">
            <Search {...searchComponentProps} isMobile />
          </Link>
        </div>
      </div>
    </CartLayout>
  )
}

export default Order
