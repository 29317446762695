import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import SearchResultRow from "./SearchResults/SearchResultRow"
import styles from "./utils/search.module.scss"

const SearchResults = ({
  searchResults,
  isExactMatch,
  onSelect,
  setSearchResults,
  searchQuery,
}) => {
  const sortedSearchResults = searchResults.sort((a, b) => {
    return a.closestMatch === "molecule" &&
      a.productTitle.includes("Value Brand") &&
      a.distance >= b.distance
      ? -1
      : 1
  })

  let hasSomeMatch = false

  let queryLowerCase = searchQuery.toLowerCase()

  // Check each productTitle for inclusion of searchQuery
  for (const result of searchResults) {
    const productTitleLowerCase = result.productTitle.toLowerCase()
    if (productTitleLowerCase.includes(queryLowerCase)) {
      // If found, return true
      hasSomeMatch = true
    }
  }

  return (
    <section className={classNames(styles["searchResult"])}>
      <ol className={classNames({ "is-hidden": !searchResults.length })}>
        {searchResults.length > 0 && !hasSomeMatch && (
          <li className={classNames(styles["errorMessage"], "is-size-7")}>
            <FontAwesomeIcon icon={faExclamationCircle} />{" "}
            <b>Cannot find item that matches the search description.</b> Please
            type the drug again or check if any of the items below match what
            you're looking for.
          </li>
        )}
        {sortedSearchResults.map((result) => (
          <SearchResultRow
            result={result}
            handleClick={() => {
              if (onSelect) onSelect(result)
              setSearchResults([])
            }}
          />
        ))}
      </ol>
    </section>
  )
}

export default SearchResults
