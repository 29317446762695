import React from "react"
import Layout from "../../components/Layout/Layout"
import Order from "../../components/Epharmacy/Order"

export default ({ location }) => {
  return (
    <Layout
      title="Order Medicines"
      subtitle="Search for medicines and add them to your cart."
      process="epharmacy"
      seoTitle="Order Medicines"
      display={{ footer: false, helpCenterBanner: false }}
      path={location?.pathname}
    >
      <Order nextRoute="/epharmacy/checkout" backRoute="/epharmacy/upload" location={location} />
    </Layout>
  )
}
