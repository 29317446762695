import { useStaticQuery, graphql } from "gatsby"

import { reformatAirtableData } from "../services/reformatAirtableData"

const ALL_EPHARMACY_PRODUCTS = graphql`
  query {
    allAirtableallViatrisProducts(
      filter: { data: { ShowOnWebsite: { eq: true } } }
    ) {
      nodes {
        data {
          brand: Brand
          molecule: Molecule
          productTitle: ProductTitle
          rxRequired: RxRequired
          rxLevel: DrugRxLevel
          classification: Classification
          priceList: PriceList
          vatexUnitPrice: VatexUnitPrice
          vat: Vat
          drugName: DrugName
          form: Form
          skuTags: SKUTags
          strength: Strength
          productID: Product_ID
          tags: Tags
        }
      }
    }
  }
`

export const useEpharmacyProducts = () => {
  const QUERY = useStaticQuery(ALL_EPHARMACY_PRODUCTS)
  let products = QUERY?.allAirtableallViatrisProducts?.nodes
  products = products?.map((product) => reformatAirtableData(product.data))

  return products
}
