import { graphql, useStaticQuery } from "gatsby"

const ALL_EMPLOYEES_QUERY = graphql`
  query AirtableEmployees {
    allAirtableEmployees {
      nodes {
        data {
          employeeId: Employee_ID
          employeeType: Employee_Type
        }
      }
    }
  }
`

export const useEmployees = () => {
  let allEmployees = useStaticQuery(ALL_EMPLOYEES_QUERY)
  allEmployees = allEmployees.allAirtableEmployees.nodes.map((employeeID) => {
    return {
      employeeId: employeeID.data.employeeId,
      employeeType: employeeID.data.employeeType,
    }
  })

  return allEmployees
}
