import React from "react"
import RxIndicator from "../../Elements/RxIndicator"
import { generatePrice } from "../../Epharmacy/services/computations"

const SearchResultRow = ({ result, handleClick }) => (
  <li onClick={() => handleClick()}>
    <div className="is-flex has-text-weight-bold">
      <div>{result.productTitle}</div>
      {result.rxRequired && <RxIndicator />}
    </div>
    <div className="is-size-7 mt-1">
      {result?.price_list?.context || ` Php ${generatePrice(result)}`}
    </div>
  </li>
)

export default SearchResultRow
